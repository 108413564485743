import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import Loader from "./components/common/full-page-loader";

import "./styles/globals.scss";

const App = React.lazy(() => import("./components/app"));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader text="Loading App..." />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
