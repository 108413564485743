import React from "react";
import { Spin } from "antd";

import "./styles.scss";

const Loader = ({ text }) => {
  return (
    <div className="full-page-loader">
      <Spin size="middle" tip={text} />
    </div>
  );
};

export default Loader;
